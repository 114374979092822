import { useEffect } from 'react';
import QueryString from 'query-string';
import './App.css';
import crypto from "crypto";
import React from 'react';
import {WEBHOOK_ENDPOINTS} from "./constants";

declare var ZoomMtg:any

ZoomMtg.setZoomJSLib('https://source.zoom.us/1.9.6/lib', '/av');

ZoomMtg.preLoadWasm();
ZoomMtg.prepareJssdk();
// loads language files, also passes any error messages to the ui
ZoomMtg.i18n.load('en-US');
ZoomMtg.i18n.reload('en-US');

function App(props:any) {


  // setup your signature endpoint here: https://github.com/zoom/websdk-sample-signature-node.js
  var signatureEndpoint = ''
  var apiSecret = process.env.REACT_APP_API_SECRET;
  var apiKey = process.env.REACT_APP_API_KEY;
  var meetingNumber = '9570716460'
  var role = 0
  var leaveUrl = 'https://www.flow.zoimeet.com'
  var userName = 'Flow AI by Zoimeet'
  var userEmail = 'tech@zoimeet.com'
  var passWord = 'cJyg2k'
  var webhook = 'https://localhost:5000/zoomwebhook'
  var waitTimer: NodeJS.Timeout | undefined = undefined;
  var progressTimer: NodeJS.Timeout | undefined = undefined;
  var waitingRoomTimer: NodeJS.Timeout | undefined = undefined;
  var zoomUserId = undefined;
  var PvtMessageReceived = false;

  useEffect(() => {
    const refCode = QueryString.parse(props.history.location.search);
    console.log(refCode);
    if (refCode) {

      meetingNumber = refCode.meetingId as string;
      passWord = refCode.meetingPwd as string;
      userEmail = refCode.userEmail as string;
      userName = refCode.userName as string;
      webhook = refCode.webhook as string;
      webhook = webhook.replace(/\/$/, "");
      getSignature();
    }

  }, []);


  const monitorDomAction = () => {
    try {
      const targetNode: any = document.getElementById('wc-container-right');

      // Options for the observer (which mutations to observe)
      const config = { attributes: true, childList: true, subtree: true };

      // Callback function to execute when mutations are observed
      const callback = function (mutationsList: any, observer: any) {
        // Use traditional 'for loops' for IE 11
        for (const mutation of mutationsList) {
          if (mutation.type === 'childList' &&
            (mutation.target && mutation.target.className == "chat-item__chat-info-header" &&
              mutation.target.innerText.toLowerCase().includes("privately"))
            && !PvtMessageReceived
          ) {
            console.log("New message from flow user");
            let msg_url = webhook + WEBHOOK_ENDPOINTS.CHAT_MESSAGE;
            invokeCallback(msg_url, undefined);
            PvtMessageReceived = true
          }
        }
      };

      // Create an observer instance linked to the callback function
      const observer = new MutationObserver(callback);
      observer.observe(targetNode, config);
    } catch (er) {
      console.log(er)
    }

  }

  function invokeCallback(url: string, callback: any, method:string='GET', params:any=null) {
    if (url && webhook) {
      var http = new XMLHttpRequest();
      http.open(method, url, true);
      http.onreadystatechange = function () {
        if (http.readyState == 4 && http.status == 200) {
          // alert(http.responseText);
          console.log(http.responseText);
        }
      }
      if (params) {
        console.log('params is present.... !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!');
        params = JSON.stringify(params);
        http.setRequestHeader('Content-type', 'application/json');
      }
      http.send(params);
    }
  }

  function getSignature() {

    return new Promise((resolve, reject) => {

      const timestamp = new Date().getTime() - 30000
      const msg = Buffer.from(apiKey + meetingNumber + timestamp + role).toString('base64')
      const hash = crypto.createHmac('sha256', apiSecret as crypto.BinaryLike).update(msg).digest('base64')
      const signature = Buffer.from(`${apiKey}.${meetingNumber}.${timestamp}.${role}.${hash}`).toString('base64')
      console.log("SING", signature)
      startMeeting(signature);

    });


  }

  function startMeeting(signature: string) {
    document.getElementById('zmmtg-root')!.style.display = 'block'

    ZoomMtg.init({
      leaveUrl: leaveUrl,
      isSupportAV: true,
      success: (success: any) => {
        console.log('Init method success : ',success)
        ZoomMtg.inMeetingServiceListener('onMeetingStatus', function (data: any) {
          // {status: 1(connecting), 2(connected), 3(disconnected), 4(reconnecting)}
          console.log('onMeetingStatus listner fired : ', data);
          let mtgStatus:number = data.meetingStatus;
          let callbk_url = null;
          switch (mtgStatus) {
            case 1:
            case 4:{
              if (progressTimer) {clearTimeout(progressTimer!);}
              if (waitingRoomTimer) {clearTimeout(waitingRoomTimer!);}
              console.log('Progress timer set after 5 seconds!!!!!')
              progressTimer = setTimeout(() => {
                /// If no status callback is received after connecting status for 8 seconds, it's likely that something may have gone wrong.
                /// Will invoke a callback function. Let the caller inspect UI to see what happened.
                let unknown_callbk_url = webhook + WEBHOOK_ENDPOINTS.UNKNOWN_ERROR;
                invokeCallback(unknown_callbk_url, undefined);
              }, 15000)
              var params = "status=" + data.meetingStatus;
              callbk_url = webhook + WEBHOOK_ENDPOINTS.MEETING_STATUS + '?' + params;
              break;
            }
            case 2: {
              console.log("Clearing progress timer!!");
              if (waitingRoomTimer) {clearTimeout(waitingRoomTimer!);}
              clearTimeout(progressTimer!);
              var params = "status=" + data.meetingStatus;
              callbk_url = webhook + WEBHOOK_ENDPOINTS.MEETING_STATUS + '?' + params;
              break;
            }
            case 3: {
              console.log("Clearing progress timer!!");
              if (waitingRoomTimer) {clearTimeout(waitingRoomTimer!);}
              clearTimeout(progressTimer!);
              callbk_url = webhook + WEBHOOK_ENDPOINTS.MEETING_ENDED + '?reason=disconnected';
              break;
            }
            default:
              break;
          }
          if (callbk_url) {
            invokeCallback(callbk_url, undefined);
          }
          
          /*
          if (data.meetingStatus == 3) {            
            callbk_url = webhook + WEBHOOK_ENDPOINTS.MEETING_ENDED + '?reason=disconnected';
          }
          else if (data.meetingStatus == 1 || data.meetingStatus == 4) {
              console.log('Progress timer set after 5 seconds!!!!!')
              progressTimer = setTimeout(() => {
                /// If no status callback is received after connecting status for 8 seconds, it's likely that something may have gone wrong.
                /// Will invoke a callback function. Let the caller inspect UI to see what happened.
                callbk_url = webhook + WEBHOOK_ENDPOINTS.UNKNOWN_ERROR;
                invokeCallback(callbk_url, undefined);
              }, 8000)
              var params = "status=" + data.meetingStatus;
              callbk_url = webhook + WEBHOOK_ENDPOINTS.MEETING_STATUS + '?' + params;
            }
            else { 
              var params = "status=" + data.meetingStatus;
              callbk_url = webhook + WEBHOOK_ENDPOINTS.MEETING_STATUS + '?' + params;
            }
          invokeCallback(callbk_url, undefined);
          */
        });

        ZoomMtg.inMeetingServiceListener('onUserLeave', function (data:any) {
          console.log('onUserLeave listner fired : ',data);
          // Wait one second before getting attendees list. otherwise it'll include the user that currently left also.
          const getAttendeesList = function() {
            ZoomMtg.getAttendeeslist({
              success: function (res: any) {
                const att_list:any = res.result.attendeesList;
                if (att_list) {
                  console.log(res, " get getAttendeeslist");
                  console.log("Number of attendees : ", att_list.length);
                  if (att_list.length == 1) {
                    const lastAttendee = att_list[0];
                    console.log("Last attendee is : ", lastAttendee);
                    console.log("Set wait timer for 5 mins");
                    //TODO: Check if the last user is me. Can compare displayname. See if we can get bot's user id also. 
                    /**
                     * If the bot is the last user, wait for 5 mins to confirm whether anyone joins. 
                     * If not, then after 5 mins invoke the webhook.
                     */
                    waitTimer = setTimeout(() => {
                      ///TODO: Compare the userId also
                      let callbk_url = webhook + WEBHOOK_ENDPOINTS.MEETING_ENDED+ '?reason=lastUser';
                      invokeCallback(callbk_url, undefined);
                    }, 5000*60)

                  }
                }
              }
            });
          }
          setTimeout(() => {
            getAttendeesList();
          }, 1000);
          
        });

        ZoomMtg.inMeetingServiceListener('onUserJoin', function (data:any) {
          console.log('onUserJoin listner fired : ',data);
          // Wait one second before getting attendees list. otherwise it'll include the user that currently left also.
          const getAttendeesList = function() {
            ZoomMtg.getAttendeeslist({
              success: function (res: any) {
                const att_list: any = res.result.attendeesList;
                if (att_list) {
                  console.log(res, " get getAttendeeslist");
                  console.log("Number of attendees : ", att_list.length);
                  /// If the meeting has more than one user, then cancel any timers scheduled. 
                  if (att_list.length > 1) {
                    if (waitTimer) {
                      console.log("Clearing wait timer!!");
                      clearTimeout(waitTimer);
                    }
                  }
                }
              }
            });
          }
          setTimeout(() => {
            getAttendeesList();
          }, 2000);
          
        });

        ZoomMtg.inMeetingServiceListener('onUserIsInWaitingRoom', function (data:any) {
          console.log('onUserIsInWaitingRoom : ', data);
          let callbk_url = webhook + WEBHOOK_ENDPOINTS.WAITING_ROOM;
          invokeCallback(callbk_url, undefined);
          if (waitingRoomTimer) {clearTimeout(waitingRoomTimer!);}
          ZoomMtg.getCurrentMeetingInfo({
            success: function (res: any) {
              console.log('Get current meeting info  - User in waiting room : ',res);
            },
          });
          waitingRoomTimer = setTimeout(() => {
            /// If no status callback is received after this callback for 20 mins, it's likely that user may have ended the meeting.
            /// Will invoke a callback function. Let the caller inspect UI to see what happened.
            let unknown_callbk_url = webhook + WEBHOOK_ENDPOINTS.UNKNOWN_ERROR;
            invokeCallback(unknown_callbk_url, undefined);
          }, 1000*60*20)
        });

        ZoomMtg.join({
          signature: signature,
          meetingNumber: meetingNumber,
          userName: userName,
          apiKey: apiKey,
          userEmail: userEmail,
          passWord: passWord,
          success: (success: any) => {
            console.log('Joining successsss : ',success)
            ZoomMtg.showJoinAudioFunction({
              show: true
            });

            ZoomMtg.record({
              record: false
            });
            ZoomMtg.showRecordFunction({
              show: true
            });
            ZoomMtg.getCurrentUser({
              success: function (res: any) {
                let current_user = res.result.currentUser;        
                console.log("success getCurrentUser", current_user);
                if (!current_user.isHold) {/// Check if the user is on hold. If on hold the userId will be temporary.
                  zoomUserId = current_user.userId;
                  /// User successfully joined the meeting. If this is false, that means the user is in waiting room.
                  let callbk_url = webhook + WEBHOOK_ENDPOINTS.USER_JOINED;
                  invokeCallback(callbk_url, undefined);
                  setTimeout(() => {
                    monitorDomAction();
                  }, 5000);
                  if (waitingRoomTimer) {clearTimeout(waitingRoomTimer!);}
                }
              },
            });
          },
          error: (error: any) => {
            console.log('Error in joining meeting : ',error);
            let callbk_url = webhook + WEBHOOK_ENDPOINTS.JOIN_ERROR;
            let params = (error) ? error : null;
            invokeCallback(callbk_url, undefined, 'POST', params);
          }
        })
      },
      error: (error: any) => {
        console.log('Init method error : ',error);
      }
    })
  }

  return (
    <div className="App">
      <main>
        {/* <button onClick={getSignature}>Join Meeting</button> */}
      </main>
    </div>
  );
}

export default App;

